var _a;
import { __read, __spreadArray } from "tslib";
import { readCookie, generateQueryParams, getErrorMessage } from "./utils";
(function () {
    window.Webtrends.WebtrendsCdtBroadcaster = function (tag, plugin) {
        var _a;
        var paramName = (_a = plugin === null || plugin === void 0 ? void 0 : plugin.paramName) !== null && _a !== void 0 ? _a : "_wt";
        if (Array.isArray(plugin.appids) && plugin.appids.length) {
            plugin.appids.forEach(function (id) {
                var _a;
                var app = document.getElementById(id);
                if (app)
                    (_a = window.Webtrends) === null || _a === void 0 ? void 0 : _a.addEventListener(app, "click", refreshLink);
            });
        }
        if (Array.isArray(plugin.domains) && plugin.domains.length > 0) {
            var links = document.getElementsByTagName("A");
            __spreadArray([], __read(links), false).forEach(function (link) {
                var _a;
                if ((_a = plugin.domains) === null || _a === void 0 ? void 0 : _a.some(function (domain) {
                    var _a, _b;
                    return ((_a = link === null || link === void 0 ? void 0 : link.href) === null || _a === void 0 ? void 0 : _a.includes(domain)) &&
                        !((_b = link === null || link === void 0 ? void 0 : link.href) === null || _b === void 0 ? void 0 : _b.includes(paramName));
                })) {
                    window.Webtrends.addEventListener(link, "click", refreshLink);
                }
            });
        }
        /**
         * Refreshes the link by appending Webtrends CDT Broadcaster query parameters to the URL.
         * @param e - The event object.
         */
        function refreshLink(e) {
            var _a;
            var lnk = e.target;
            if (!lnk.href)
                throw new Error("No href found");
            var sourceURL = new URL(lnk.href);
            if (sourceURL.searchParams.has(paramName))
                return;
            try {
                var encode = (_a = plugin === null || plugin === void 0 ? void 0 : plugin.encode) !== null && _a !== void 0 ? _a : true;
                var cookieData = readCookie(tag);
                var queryParams = generateQueryParams(cookieData, plugin);
                sourceURL.searchParams.append(paramName, encode ? encodeQueryString(queryParams) : queryParams.toString());
                lnk.href = sourceURL.href;
            }
            catch (error) {
                console.error("Webtrends CDT Broadcaster: ".concat(getErrorMessage(error)));
            }
        }
        /**
         * Encodes the given URLSearchParams object into a base64-encoded string.
         * @param queryParams - The URLSearchParams object to encode.
         * @returns The base64-encoded string representation of the URLSearchParams object.
         */
        function encodeQueryString(queryParams) {
            return btoa(queryParams.toString());
        }
    };
})();
/**
 * Loads the Webtrends CDT Broadcaster plugin.
 * @param tag - The tag object.
 * @param plugin - The plugin object.
 */
var cdt_broadcaster_loader = function (tag, plugin) {
    if (document.readyState === "loading") {
        window.addEventListener("DOMContentLoaded", function () {
            return window.Webtrends.WebtrendsCdtBroadcaster(tag, plugin);
        });
    }
    else {
        window.Webtrends.WebtrendsCdtBroadcaster(tag, plugin);
    }
};
(_a = window.Webtrends) === null || _a === void 0 ? void 0 : _a.registerPlugin("cdt_broadcaster", cdt_broadcaster_loader);
